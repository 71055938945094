import { template as template_a90f99711210451e95b2e424bd7ef49e } from "@ember/template-compiler";
const FKControlMenuContainer = template_a90f99711210451e95b2e424bd7ef49e(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
