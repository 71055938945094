import { template as template_6a3a8feb1265415e97cb3eb57da2951c } from "@ember/template-compiler";
import concatClass from "discourse/helpers/concat-class";
const FKSection = template_6a3a8feb1265415e97cb3eb57da2951c(`
  <div class={{concatClass "form-kit__section" @class}} ...attributes>
    {{#if @title}}
      <h2 class="form-kit__section-title">{{@title}}</h2>
    {{/if}}

    {{#if @subtitle}}
      <span class="form-kit__section-subtitle">{{@subtitle}}</span>
    {{/if}}

    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKSection;
