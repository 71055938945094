import { template as template_f6c99d5497e14e7f8ab592b825fc096b } from "@ember/template-compiler";
const FKLabel = template_f6c99d5497e14e7f8ab592b825fc096b(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
